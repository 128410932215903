<template>
  <van-popover
    v-model="showPopover"
    trigger="click"
    :placement="placement"
  >
    <div class="current-popover-item-list">
      <div
        :class="{'item-list-item': true, 'item-list-item-select': value == item.value}"
        v-for="(item, i) in options"
        :key="i"
        @click="select(item)"
      >{{item.label}}</div>
    </div>
    <template #reference>
      <slot></slot>
    </template>
  </van-popover>
</template>

<script>

export default {
  name: "current-popover",
  components: {

  },
  props: {
    //选项列表，格式：[{ value: 1, label: '普通支付' }, { value: 2, label: '授信支付' }]
    options: {
      type: Array,
      default: [],
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    //选中的值
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  mounted() {
    if (this.options.length > 0) {
      let index = this.options.map(item => item.value).indexOf(this.value);
      if (index != -1) {
        this.$emit('init', this.options[index]);
      }
    }
  },
  methods: {
    select(item) {
      this.$emit('input', item.value);
      this.$emit('select', item);
      this.showPopover = false;
    },
  },
  computed: {

  },
  watch: {
    // value: {
    //   immediate: true,
    //   handler(val) {


    //     }
    //   }
    // }
  }
};
</script>
<style lang="scss" scoped>
.current-popover-item-list {
  padding: 28px 36px 26px 36px;
  .item-list-item {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 400;
    color: #999999;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .item-list-item-select {
    color: #2181ed;
  }
}
</style>
