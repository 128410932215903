<template>
  <div class="container">
    <navbar title="授信明细"></navbar>
    <c-sticky :offset-top="$px2vwMin(92)">
      <div class="top-operate">
        <div
          class="top-operate-item"
          @click="showYearMonthPicker=true;"
        >{{yearMonthLabel}}</div>
      </div>
    </c-sticky>
    <year-month-picker
      v-model="showYearMonthPicker"
      :defaultValue="[condition.year, condition.month]"
      @confirm="yearMonthConfirm"
      @init="setYearMonth"
    ></year-month-picker>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="list-block"
          v-for="(item, i) in list"
          :key="i"
        >
          <div class="date-money">
            <div class="block-date">{{item.time}}</div>
          </div>
          <div class="money-detail-list">
            <div
              class="money-detail-list-item"
              v-for="(item2, j) in item.detail"
              :key="i+'_'+j"
              @click="toOrder(item2)"
            >
              <div class="item-left">
                <div class="item-date-money">
                  <div class="item-date">{{$moment(item2.created_time).format("MM月DD日 HH:mm")}}</div>
                  <div :class="{'item-money': true, 'color-red': item2.change < 0, 'color-green': item2.change > 0}" v-if="item2.type != 1 && item2.change != 0">{{item2.change > 0 ? '+' : ''}}{{item2.change}}</div>
                </div>
                <div
                  class="item-money-detail"
                  v-if="item2.type != 1 && item2.pay_type == 1"
                >
                <div>授信支付</div>
                <div v-if="item2.type == 2" class="color-red">拨款失败</div>
                </div>
                <div
                  class="item-money-detail"
                  v-if="item2.type != 1 && item2.pay_type == 2"
                >
                <div style="flex:1">组合支付（授信支付￥{{item2.creditPay}}，普通支付￥{{item2.pay}}）</div>
                <div v-if="item2.type == 2" class="color-red err-msg">拨款失败</div>
                </div>
                <div
                  class="item-money-detail"
                  v-if="item2.type == 1"
                  style="justify-content: flex-start;"
                >额度更新（总额度<span class="color-blue">￥{{item2.credit_line}}</span>，剩余额度<span class="color-blue">￥{{item2.creadit_line_after}}）</span>
                </div>
              </div>
              <div class="item-right" v-if="item2.type != 1">
                <van-icon name="arrow" :size="$px2vw(25)" color="#999999"/>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import CSticky from "@/components/c-sticky.vue";
import Navbar from "@/components/navbar.vue";
import CurrentPopover from '@/components/current-popover.vue';
import YearMonthPicker from '@/components/year-month-picker.vue';
import { creditRecord } from '@/api/credit.js';
export default {
  name: "creditRecord",
  components: {
    CSticky,
    Navbar,
    CurrentPopover,
    YearMonthPicker,
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      showPayTypePopover: false,
      payTypeLabel: '',

      showYearMonthPicker: false,
      yearMonthLabel: '',

      condition: {
        year: new Date().getFullYear(),
        month: ''
      }
    };
  },
  mounted() {

  },
  methods: {
    search() {
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      this.loading = true;
      let data = { page: this.page};
      if (this.condition.month != '') {
        data['time'] = this.$moment(this.condition.year + '-' + this.condition.month, "YYYY-MM").endOf('month').format("YYYY-MM-DD");  //月份最后一天
      } else {
        data['year'] = this.condition.year;
      }
      creditRecord(data).then(res => {
        if (res.code == 0) {
          this.finished = true;
          this.list = res.data;
          this.loading = false;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //设置选中年月
    setYearMonth(arr) {
      this.$set(this.condition, 'year', arr[0]['value']);
      this.$set(this.condition, 'month', arr[1]['value']);
      this.yearMonthLabel = arr[0]['alias'] + arr[1]['alias'];
    },
    //选中年月
    yearMonthConfirm(arr) {
      this.setYearMonth(arr);
      this.search();
    },
    toOrder(item) {
      if (item.removed_time) {
        return this.$toast('订单已删除')
      }
      if (item.order_id > 0) {
        return this.$router.push('/order/detail/'+item.order_id);
      }
    }
  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.container {
  min-height: 100vh;
  :deep(.van-list__finished-text) {
    margin: 0 20px;
  }
  .top-operate {
    height: 88px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 0 30px;
    box-sizing: border-box;
    .top-operate-item {
      padding-right: 34px;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 30px;
      font-weight: bold;
      color: #666666;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      &:after {
        content: "";
        position: absolute;
        right: 0;
        width: 18px;
        height: 9px;
        background: url(~@/assets/images/icon/arrow_down.png) no-repeat center;
        background-size: 18px 9px;
      }
    }
  }
  .list {
    @include safe-area-inset-bottom;
    .list-block {
      background: #F6F6F6;
      .date-money {
        padding: 20px 30px 12px;
        display: flex;
        justify-content: space-between;
        .block-date {
          font-size: 34px;
          font-weight: 500;
          color: #4f4f4f;
        }
        .block-money {
          font-size: 34px;
          font-weight: bold;
          color: #ff4d4d;
        }
      }
      .money-detail {
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        text-align: right;
        padding: 0 30px;
        margin-top: 10px;
      }
      .money-detail-list {
        // margin-top: 30px;
        .money-detail-list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #ffffff;
          padding: 32px 30px;
          border-bottom: 1px solid #ececec;
          // &:first-child {
          //   border-radius: 16px 16px 0px 0px;
          // }
          &:last-child {
            border-bottom: 0;
          }
          .item-left{
            width: 93%;
          }
          .item-right{
            width: 4%;
          }
          .item-date-money {
            display: flex;
            justify-content: space-between;
            .item-date {
              font-size: 30px;
              font-weight: 400;
              color: #4f4f4f;
            }
            .item-money {
              font-size: 30px;
              font-weight: bold;
            }
          }
          .item-money-detail {
            display: flex;
            justify-content: space-between;
            font-size: 24px;
            font-weight: 400;
            color: #999999;
            margin-top: 11px;
            .err-msg{
              width: 100px;
              text-align: right;
            }
          }
        }
      }
      .color-red{
        color: #F33636;
      }
      .color-green{
        color: #07C160;
      }
      .color-blue{
        color: #006AFA;
      }
    }
  }
}
</style>
