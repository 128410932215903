
<template>
  <van-popup
    v-model="show"
    get-container="body"
    position="bottom"
    class="year-month-picker"
    round
  >
    <van-picker
      show-toolbar
      :columns="columns"
      @confirm="confirm"
      @cancel="show = false"
      :item-height="$px2vw(76)"
      :visible-item-count="5"
      ref="yearMonthPicker"
      confirm-button-text="确定"
    />
  </van-popup>
</template>

<script>

export default {
  name: "year-month-picker",
  components: {

  },
  props: {
    //显示隐藏组件
    value: {
      type: Boolean,
      default: false,
    },
    //默认值，格式：[2022, '']或[2022, 1]，第二个空为全年
    defaultValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //最小值，包含年月
    minValue: {
      type: Array,
      default: () => {
        return [2022, 1]
      },
    },
    //最大值，包含年月
    maxValue: {
      type: Array,
      default: () => {
        let date = new Date();
        return [date.getFullYear(), date.getMonth() + 1];
      },
    }
  },
  data() {
    return {
      columns: [],
    };
  },
  mounted() {
    this.createSelectData();
    let index = this.getSelectIndex(this.defaultValue);
    let ret = this.getSelectItems(index);
    this.$emit('init', ret);
  },
  methods: {
    confirm(values, indexs) {
      let ret = this.getSelectItems(indexs);
      this.$emit('confirm', ret);
      this.show = false;
    },
    //生成选项
    createSelectData() {
      this.columns = [];
      for (let year = this.minValue[0]; year <= this.maxValue[0]; year++) {
        let tmpYear = {
          value: year,
          text: year,
          alias: year + '年',
        };
        let children = [];
        for (let month = 0; month <= 12; month++) {
          if (month == 0) {
            children.push({ value: '', text: '全年', alias: '全年' });
          } else if ((year == this.minValue[0] && month >= this.minValue[1])
            || (year == this.maxValue[0] && month <= this.maxValue[1])
            || (year != this.minValue[0] && year != this.maxValue[0])) {
            children.push({ value: month, text: month, alias: month + '月' });
          }
        }
        if (children.length > 0) tmpYear['children'] = children;
        this.columns.push(tmpYear);
      }
    },
    //设置选中，格式[xx,xx]
    setSelect(val) {
      this.$nextTick(() => {
        let setIndexs = this.getSelectIndex(val);
        this.$refs.yearMonthPicker.setIndexes(setIndexs);
      })
    },
    //获取值对应的索引
    getSelectIndex(val) {
      let setIndexs = [];
      let index1 = this.columns.map(item => item.value).indexOf(val[0]);
      if (index1 == -1) return false;
      setIndexs.push(index1);
      let index2 = this.columns[index1].children.map(item => item.value).indexOf(val[1]);
      if (index2 != -1) setIndexs.push(index2);
      return setIndexs;
    },
    //获取选中的项
    getSelectItems(indexs) {
      let ret = [this.columns[indexs[0]], this.columns[indexs[0]].children[indexs[1]]];
      return ret;
    }
  },
  computed: {
    show: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setSelect(this.defaultValue);
        }
      }
    },
    minValue() {
      this.createSelectData();
    },
    maxValue() {
      this.createSelectData();
    },
  }
};
</script>
<style lang="scss" scoped>
.year-month-picker {
  height: 500px;
  @include safe-area-inset-bottom;
  :deep(.van-picker-column__item) {
    color: rgba($color: #4f4f4f, $alpha: 0.2);
    font-size: 36px;
    font-weight: bold;
  }
  :deep(.van-picker-column__item--selected) {
    color: #1a8dff;
  }
  :deep(.van-picker-column) {
    z-index: 3;
  }
  :deep(.van-picker__frame) {
    background: #eff9ff;
    left: 0;
    right: 0;
  }
  :deep(.van-hairline-unset--top-bottom::after) {
    border: 0;
  }
  :deep(.van-picker__toolbar) {
    height: 100px;
    .van-picker__cancel {
      font-size: 36px;
      font-weight: 500;
      color: #999999;
      padding: 30px 40px 20px 40px;
    }
    .van-picker__confirm {
      font-size: 36px;
      font-weight: 500;
      color: #1aa3ff;
      padding: 30px 40px 20px 40px;
    }
  }
}
</style>
